<template>
  <!--    编辑-->
  <el-dialog
    :title="formTitle"
    :visible.sync="messageBox"
    width="500px"
    class="addAlameBox"
    :close-on-click-modal="false"
  >
    <FormBox
      ref="formRef"
      :showFlag="showFlag"
      :formArr="formArr"
      :record="addForm"
      labelWidth="6vw"
      :fileListModify="fileListModify"
    ></FormBox>
  </el-dialog>
</template>

<script>
import FormBox from "@/components/lxw/Form/index.vue";
export default {
  components: { FormBox },
  props: {
    formTitle: {
      type: String,
      default: "新增",
    },
    messageBox: {
      type: Boolean,
      default: false,
    },
    showFlag: {
      type: Boolean,
      default: false,
    },
    record: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },

  data() {
    return {
      formArr: [
        {
          label: "产品名称",
          key: "inputName",
          type: "Input",
        },
        {
          label: "规格",
          key: "unit",
          type: "Input",
        },
        {
          label: "库存数量",
          key: "quantity",
          type: "InputNumber",
        },
      ],
      addForm: {},
      rules: {
        // baseName: [
        //   {required: true, message: "基地名称不可为空", trigger: ["blur", "change"]},
        // ],
      },
      fileListModify: []
    };
  },
  watch: {
    record: {
      handler(newVal, oldVal) {
        this.addForm = newVal;
        if(newVal.diseaseImg){
          this.$getBlob("/fileops/show", {
            fileName: newVal.diseaseImg,
          }).then((res) => {
            let fileNames = res.config.params.fileName;
            let fileData = fileNames.split(".")[1];
            let blob = new Blob([res.data],{type: 'image/'+ fileData})
            let url = window.URL.createObjectURL(blob);
            this.fileListModify = [{name: newVal.diseaseImg, url: url, blob, fileData}];
          })
        }else{
          this.fileListModify = [];
        }

      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    // //console.log(this.record, "record");
  },
  methods: {
    getFileListAndForm(obj) {
      if (obj && obj.fileList) {
        const { fileList } = obj;
        let list = [];
        fileList.forEach((item) => {
          if (
            item.response &&
            item.response.data &&
            item.response.data.fileName
          ) {
            list.push(item.response.data.fileName);
          }
        });
        this.filesList = list;
      }
    },

  },
};
</script>

<style scoped>

</style>